<template>
  <div
    :class="
      `my-search-container flex place-items-center mt-4 sm:mt-0 relative ${
        isGrey ? 'grey' : ''
      }`
    "
  >
    <label v-show="showLabel && value == ''" class="absolute" :for="id">
      {{ label }}
    </label>
    <input
      @focus="showLabel = false"
      @blur="showLabel = true"
      type="text"
      v-model="value"
      :id="id"
      @keyup="valued"
    />
    <svg
      class="mx-auto"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM19.485 18.071L22.314 20.899L20.899 22.314L18.071 19.485L19.485 18.071Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  data: () => ({
    showLabel: true,
    value: "",
    id: "",
  }),
  created() {
    this.id = `input-${Math.random()}`;
  },
  methods: {
    valued() {
      this.$emit("input", this.value);
    },
  },
  props: {
    isGrey: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
// @import "../../assets/scss/imports/colors.scss";
.my-search-container {
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(22, 121, 168, 0.25);
  border-radius: 6px;
  padding: 9px 14px;
  input {
    width: 90%;
    &:focus {
      outline: none;
    }
  }
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  svg {
    fill: #032b3d;
  }
  label,
  input {
    color: #4c4c4c;
  }
  &.grey {
    svg {
      fill: #9d9ca5;
    }
    label,
    input {
      color: #bbbabd;
    }
  }
}
</style>
